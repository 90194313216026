import { createSlice } from "@reduxjs/toolkit";

export const ArticleInitialState = {
  articles: [],
  articleDetails: [],
  articleById: null,
  articlesByFournisseur: [],
  articlesWithoutFournisseur: [],
  articleNumber: 0,
  tagNonActive: [],
  articlesStockAlert: [],
  dlc:[]
};

export const articleSlice = createSlice({
  name: "article",
  initialState: ArticleInitialState,
  reducers: {
    setArticles: (state, action) => {
      state.articles = action.payload.articles;
    },
    setArticleDetails: (state, action) => {
      state.articleDetails = action.payload.articleDetails;
    },
    setArticleById: (state, action) => {
      state.articleById = action.payload.articleById;
    },
    setArticlesByFournisseur: (state, action) => {
      state.articlesByFournisseur = action.payload.articlesByFournisseur;
    },
    setArticlesWithoutFournisseur: (state, action) => {
      state.articlesWithoutFournisseur =
        action.payload.articlesWithoutFournisseur;
    },
    setArticleNumber: (state, action) => {
      state.articleNumber = action.payload.articleNumber;
    },
    setTagNonActive: (state, action) => {
      state.tagNonActive = action.payload.tagNonActive;
    },
    setArticlesStockAlert: (state, action) => {
      state.articlesStockAlert = action.payload.articlesStockAlert;
    },
    setDlc: (state, action) => {
      state.dlc = action.payload.dlc;
    },
  },
});

export const {
  setArticles,
  setArticleDetails,
  setArticleById,
  setArticlesByFournisseur,
  setArticlesWithoutFournisseur,
  setArticleNumber,
  setTagNonActive,
  setArticlesStockAlert,
  setDlc
} = articleSlice.actions;
