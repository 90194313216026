import { createSlice } from "@reduxjs/toolkit";

export const RolesInitialState = {
  roles: [],
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState: RolesInitialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload.roles;
    },
  
  },
});

export const { setRoles } = rolesSlice.actions;
