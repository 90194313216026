import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import TranslationFR from "locales/Francais/TranslationFR";



if (!i18n.isInitialized) {
  i18n.use(LanguageDetector).init({
    resources: {
      fr: {
        translation: TranslationFR,
      },
    },
    fallbackLng: "en",
    missingKeyHandler: false,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["navigator"],
    },
  });
  // document.documentElement.lang = savedLanguage;
  document.documentElement.setAttribute("translate", "no");
}
export default i18n;
