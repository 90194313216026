import { createSlice } from "@reduxjs/toolkit";

export const RolePermissionInitialState = {
  rolePermission: [],
};

export const rolePermissionSlice = createSlice({
  name: "rolePermission",
  initialState: RolePermissionInitialState,
  reducers: {
    setRolePermission: (state, action) => {
      state.rolePermission = action.payload.rolePermission;
    },
  },
});

export const { setRolePermission } = rolePermissionSlice.actions;
