import { createSlice } from "@reduxjs/toolkit";

export const PermissionInitialState = {
  permissions: [],
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState: PermissionInitialState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload.permissions;
    },
  },
});

export const { setPermissions } = permissionSlice.actions;
