import React, { useEffect, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import NotAllowed from "views/auth/NotAllowed";
import Loader from "components/loader";
import CacheBuster from "react-cache-buster";
import packageFile from "../package.json";

// Lazy load layout components
const RtlLayout = React.lazy(() => import("layouts/rtl"));
const AdminLayout = React.lazy(() => import("layouts/admin"));
const AuthLayout = React.lazy(() => import("layouts/auth"));
const SuperAdminLayout = React.lazy(() => import("layouts/superAdmin"));

const App = () => {
  const { version } = packageFile;
  const isProduction = process.env.NODE_ENV === "production";
  const auth = useSelector((state) => state.authentification.isLoggedIn);
  const loggedInUser = useSelector(
    (state) => state.authentification.loggedInUser
  );


  useEffect(() => {
    if (auth) {
      import("layouts/admin");
      import("layouts/superAdmin");
    } else {
      import("layouts/auth");
    }
  }, [auth]);

  return (
    <I18nextProvider i18n={i18n}>
      {/* <Loader isLoading={true}/> */}
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        metaFileDirectory={"."}
      >
        <Suspense fallback={<Loader isLoading={true} />}>
          <Routes>
            {/* Authenticated user routes */}
            {auth &&
              (loggedInUser?.App_Type === "Web" ||
                loggedInUser?.App_Type === "All") && (
                <>
                  {/* Render AdminLayout if loggedInUser.rb is not true */}
                  {loggedInUser.rb !== true && (
                    <Route path="admin/*" element={<AdminLayout />} />
                  )}
                  {/* Render SuperAdminLayout if loggedInUser.rb is true */}
                  {loggedInUser.rb === true && (
                    <Route path="superAdmin/*" element={<SuperAdminLayout />} />
                  )}
                </>
              )}

            {/* Unauthenticated user routes */}
            {!auth && <Route path="auth/*" element={<AuthLayout />} />}

            {/* Not allowed route based on App_Type */}
            {auth &&
              loggedInUser?.App_Type !== "Web" &&
              loggedInUser?.App_Type !== "All" && (
                <Route path="*" element={<NotAllowed />} />
              )}

            {/* Fallback route */}
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    auth
                      ? loggedInUser.rb
                        ? "/superAdmin"
                        : "/admin"
                      : "/auth/sign-in"
                  }
                  replace
                />
              }
            />

            {/* RTL layout route */}
            <Route path="rtl/*" element={<RtlLayout />} />
          </Routes>
        </Suspense>
      </CacheBuster>
    </I18nextProvider>
  );
};

export default App;
