import React from "react";
import { Spinner } from "assets/img/exports";

export default function Loader({ isLoading, text = "Loading..." }) {
  return (
    isLoading && (
      <div className="fixed inset-0 z-50 flex h-screen items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
        <div className="flex flex-col items-center">
          <img
            className="animate-pulse w-48 h-48 rounded-full border-4 border-t-transparent border-yellow-500"
            src={Spinner}
            alt="Loading spinner"
          />
          <p className="mt-4 text-white text-lg font-semibold">{text}</p>
        </div>
      </div>
    )
  );
}
