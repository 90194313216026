import { createSlice } from "@reduxjs/toolkit";

export const AttributionInitialState = {
  attributions: [],
  categoryAttributions: [],
};

export const attributionSlice = createSlice({
  name: "attribution",
  initialState: AttributionInitialState,
  reducers: {
    setAttributions: (state, action) => {
      state.attributions = action.payload.attributions;
    },
    setCategoryAttribution: (state, action) => {
      state.categoryAttributions = action.payload.categoryAttributions;
    },
  },
});

export const { setAttributions, setCategoryAttribution } =
  attributionSlice.actions;
