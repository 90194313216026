import { createSlice } from "@reduxjs/toolkit";

export const ClientInitialState = {
  clients: [],
  groupeClients: [],
  clientsByGroup: [],
};

export const clientSlice = createSlice({
  name: "client",
  initialState: ClientInitialState,
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload.clients;
    },
    setGroupeClients: (state, action) => {
      state.groupeClients = action.payload.groupeClients;
    },
    setClientsByGroup: (state, action) => {
      state.clientsByGroup = action.payload.clientsByGroup;
    },
  },
});

export const { setClients, setGroupeClients, setClientsByGroup } =
  clientSlice.actions;
