import { createSlice } from "@reduxjs/toolkit";

export const ModeInitialState = {
    modes: [],

};

export const modeSlice = createSlice({
    name: "mode",
    initialState: ModeInitialState,
    reducers: {
        setModes: (state, action) => {
            state.modes = action.payload.modes
        },
      
    },
});

export const {setModes} = modeSlice.actions;
