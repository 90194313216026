import { createSlice } from "@reduxjs/toolkit";

export const BdcInitialState = {
  bonDeCommande: [],
  commandeDetails:null,
  bonDeCommandeLine: [],
  bonDeCommandeDetails: null,
  bonDeCommandeEnCours: null,
  bonDeCommandeEnCoursCount: 0,
  bonDeCommandeRb: [],
  bonDeCommandeLineRb: null,
  allBondeCommandeRb: [],
  loadingBdc: true,
};

export const bdcSlice = createSlice({
  name: "bdc",
  initialState: BdcInitialState,
  reducers: {
    setBonDeCommande: (state, action) => {
      state.bonDeCommande = action.payload.bonDeCommande;
    },
    setCommandeDetails: (state, action) => {
      state.commandeDetails = action.payload.commandeDetails;
    },
    setBonDeCommandeLine: (state, action) => {
      state.bonDeCommandeLine = action.payload.bonDeCommandeLine;
    },
    setBonDeCommandeDetails: (state, action) => {
      state.bonDeCommandeDetails = action.payload.bonDeCommandeDetails;
    },
    setBonDeCommandeEnCours: (state, action) => {
      state.bonDeCommandeEnCours = action.payload.bonDeCommandeEnCours;
    },
    setBonDeCommandeEnCoursCount: (state, action) => {
      state.bonDeCommandeEnCoursCount =
        action.payload.bonDeCommandeEnCoursCount;
    },
    setBonDeCommandeRb: (state, action) => {
      state.bonDeCommandeRb = action.payload.bonDeCommandeRb;
    },
    setBonDeCommandeLineRb: (state, action) => {
      state.bonDeCommandeLineRb = action.payload.bonDeCommandeLineRb;
    },
    setAllBondeCommandeRb: (state, action) => {
      state.allBondeCommandeRb = action.payload.allBondeCommandeRb;
    },
    setLoadingBdc: (state, action) => {
      state.loadingBdc = action.payload.loadingBdc;
    },
  },
});

export const {
  setBonDeCommande,
  setCommandeDetails,
  setBonDeCommandeLine,
  setBonDeCommandeDetails,
  setBonDeCommandeEnCours,
  setBonDeCommandeEnCoursCount,
  setBonDeCommandeRb,
  setBonDeCommandeLineRb,
  setAllBondeCommandeRb,
  setLoadingBdc,
} = bdcSlice.actions;
