import { createSlice } from "@reduxjs/toolkit";

export const AvoirInitialState = {
  avoirs: [],
};

export const avoirSlice = createSlice({
  name: "avoir",
  initialState: AvoirInitialState,
  reducers: {
    setAvoirs: (state, action) => {
      state.avoirs = action.payload.avoirs;
    },
  },
});

export const { setAvoirs } = avoirSlice.actions;
