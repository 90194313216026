import { createSlice } from "@reduxjs/toolkit";

export const FournisseurInitialState = {
    fournisseurs: [],
    fournisseurNumber:0

};

export const fournisseurSlice = createSlice({
    name: "fournisseur",
    initialState: FournisseurInitialState,
    reducers: {
        setFournisseurs: (state, action) => {
            state.fournisseurs = action.payload.fournisseurs
        },
        setFournisseurNumber: (state, action) => {
            state.fournisseurNumber = action.payload.fournisseurNumber
        }
    },
});

export const {setFournisseurs,setFournisseurNumber} = fournisseurSlice.actions;
