import { createSlice } from "@reduxjs/toolkit";

export const FamilleTagInitialState = {
  familleTag: [],
};

export const familleTagSlice = createSlice({
  name: "familleTag",
  initialState: FamilleTagInitialState,
  reducers: {
    setFamilleTag: (state, action) => {
      state.familleTag = action.payload.familleTag;
    },
  },
});

export const { setFamilleTag } = familleTagSlice.actions;
