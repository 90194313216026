import { createSlice } from "@reduxjs/toolkit";

export const TerminauxInitialState = {
  terminaux: [],
};

export const terminauxSlice = createSlice({
  name: "roles",
  initialState: TerminauxInitialState,
  reducers: {
    setTerminaux: (state, action) => {
      state.terminaux = action.payload.terminaux;
    },
  
  },
});

export const { setTerminaux } = terminauxSlice.actions;
