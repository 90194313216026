import { createSlice } from "@reduxjs/toolkit";

export const UserInitialState = {
    users: [],
};

export const userSlice = createSlice({
    name: "user",
    initialState: UserInitialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload.users
        }
    },
});

export const {setUsers} = userSlice.actions;
