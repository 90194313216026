import { createSlice } from "@reduxjs/toolkit";

export const DashboardInitialState = {
  mostRepeatedReference: [],
  achats: [],
  topEcartAttribution: [],

  lastInventoryId: "",
  ecartInventoryParRapportInventory: [],
  ecartInventoryDifference: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: DashboardInitialState,
  reducers: {
    setMostRepeatedReference: (state, action) => {
      state.mostRepeatedReference = action.payload.mostRepeatedReference;
    },
    setAchats: (state, action) => {
      state.achats = action.payload.achats;
    },
    setTopEcartAttribution: (state, action) => {
      state.topEcartAttribution = action.payload.topEcartAttribution;
    },

    setLastInventoryId: (state, action) => {
      state.lastInventoryId = action.payload.lastInventoryId;
    },
    setEcartInventoryParRapportInventory: (state, action) => {
      state.ecartInventoryParRapportInventory =
        action.payload.ecartInventoryParRapportInventory;
    },
    setEcartInventoryDifference: (state, action) => {
      state.ecartInventoryDifference = action.payload.ecartInventoryDifference;
    },
  },
});

export const {
  setMostRepeatedReference,
  setAchats,
  setTopEcartAttribution,
  setLastInventoryId,
  setEcartInventoryParRapportInventory,
  setEcartInventoryDifference,
} = dashboardSlice.actions;
