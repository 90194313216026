import { createSlice } from "@reduxjs/toolkit";

export const ConsommateurInitialState = {
  consommateurs: [],
};

export const consommateurSlice = createSlice({
  name: "consommateur",
  initialState: ConsommateurInitialState,
  reducers: {
    setConsommateurs: (state, action) => {
      state.consommateurs = action.payload.consommateurs;
    },
  },
});

export const { setConsommateurs } = consommateurSlice.actions;
