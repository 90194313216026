import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "shared";
import { disconnect } from "shared/slice/auth/AuthSlice";

const NotAllowed = () => {
    const navigate = useNavigate();

  const loggedInUser = useSelector(
    (state) => state.authentification.loggedInUser
  );
  useEffect(() => {
    if (loggedInUser?.App_Type !== "Web") {
      store.dispatch(disconnect());
    }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="px-6 py-8">
          <div className="mb-4">
            <h1 className="text-2xl font-bold text-gray-800">
              Sorry, you are not allowed to use the dashboard on the web.
            </h1>
            <p className="text-gray-600 mt-2">Please use the mobile app instead.</p>
          </div>
          <div className="flex justify-end">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              onClick={() => navigate("/auth/sign-in")}
            >
              Go Back to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotAllowed;
