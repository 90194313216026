import { createSlice } from "@reduxjs/toolkit";

export const ModalInitialState = {
  modalFournisseur: false,
  modalArticle: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: ModalInitialState,
  reducers: {
    setModalFournisseur: (state, action) => {
      state.modalFournisseur = action.payload.modalFournisseur;
    },
    setModalArticle: (state, action) => {
      state.modalArticle = action.payload.modalArticle;
    },
  },
});

export const { setModalFournisseur, setModalArticle } = modalSlice.actions;
