import { createSlice } from "@reduxjs/toolkit";

export const InventoryInitialState = {
  inventory: [],
  inventoryLigne: [],
  ecart: [],
  ecartAttribution: null,
  ecartInventory: null,
  inventoryByAttribution: [],
  lastInventory: null,
  // mostRepeatedReference: [],
  // achats: [],
  // topEcartAttribution: [],
  // attributions: [],
  // lastInventoryId: "",
  // ecartInventoryParRapportInventory: [],
  // ecartInventoryDifference: [],
  loadingEcart: true,
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState: InventoryInitialState,
  reducers: {
    setInventory: (state, action) => {
      state.inventory = action.payload.inventory;
    },
    setInventoryLigne: (state, action) => {
      state.inventoryLigne = action.payload.inventoryLigne;
    },
    setEcart: (state, action) => {
      state.ecart = action.payload.ecart;
    },
    setEcartAttribution: (state, action) => {
      state.ecartAttribution = action.payload.ecartAttribution;
    },
    setEcartInventory: (state, action) => {
      state.ecartInventory = action.payload.ecartInventory;
    },
    setInventoryByAttribution: (state, action) => {
      state.inventoryByAttribution = action.payload.inventoryByAttribution;
    },
    setLastInventory: (state, action) => {
      state.lastInventory = action.payload.lastInventory;
    },
    // setMostRepeatedReference: (state, action) => {
    //   state.mostRepeatedReference = action.payload.mostRepeatedReference;
    // },
    // setAchats: (state, action) => {
    //   state.achats = action.payload.achats;
    // },
    // setTopEcartAttribution: (state, action) => {
    //   state.topEcartAttribution = action.payload.topEcartAttribution;
    // },
    // setAttributions: (state, action) => {
    //   state.attributions = action.payload.attributions;
    // },

    // setLastInventoryId: (state, action) => {
    //   state.lastInventoryId = action.payload.lastInventoryId;
    // },
    // setEcartInventoryParRapportInventory: (state, action) => {
    //   state.ecartInventoryParRapportInventory =
    //     action.payload.ecartInventoryParRapportInventory;
    // },
    // setEcartInventoryDifference: (state, action) => {
    //   state.ecartInventoryDifference = action.payload.ecartInventoryDifference;
    // },

    setLoadingEcart: (state, action) => {
      state.loadingEcart = action.payload.loadingEcart;
    },
  },
});

export const {
  setInventory,
  setInventoryLigne,
  setEcart,
  setEcartAttribution,
  setEcartInventory,
  setInventoryByAttribution,
  setLastInventory,
  // setMostRepeatedReference,
  // setAchats,
  // setTopEcartAttribution,
  // setAttributions,
  // setLastInventoryId,
  // setEcartInventoryParRapportInventory,
  // setEcartInventoryDifference,
  setLoadingEcart,
} = inventorySlice.actions;
